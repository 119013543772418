import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core'

import PhoneIcon from '../../svg/phone-icon.svg'
import MailIcon from '../../svg/mail-icon.svg'
import { StaticImage } from 'gatsby-plugin-image'

const useStyles = makeStyles((theme) => ({
  
  wrapper: {
    padding: '30px 0',
  },

  aboutText: {

    '& span': {
      color: [theme.palette.primary.main],
      fontWeight: 600,
    },

    '&.underlined': {
      paddingBottom: '1.5rem',
      marginBottom: '1.5rem',
      borderBottom: '1px solid',
    },
  },

  contactBox: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      width: 'auto',
      height: 'auto',
      fill: [theme.palette.text.primary],
      display: 'block',
    },

    '& a': {
      color: [theme.palette.text.primary],
      paddingLeft: '20px',
      textDecoration: 'none',
      fontWeight: 500,
      display: 'block',
      lineHeight: 1,
      transition: 'color .2s linear',
      fontSize: '2rem',

      '&:hover': {
        color: [theme.palette.primary.main]
      },
    },
  },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      position: 'relative',
      padding: '60px 0',
    },

    bgImage: {
      position: 'absolute',
      zIndex: '0',
      top: 0,
      left: '55%',
    },
    
    content: {
      position: 'relative',
      zIndex: 5,
    },

    contactsContainer: {
      display: 'flex',
      alignItems: 'center',
    },

    contactBox: {
      display: 'flex',
      alignItems: 'center',
  
      '& svg': {
        width: 'auto',
        height: 'auto',
        fill: [theme.palette.text.primary],
        display: 'block',
      },
  
      '& a': {
        color: [theme.palette.text.primary],
        paddingLeft: '30px',
        textDecoration: 'none',
        fontWeight: 500,
        display: 'block',
        lineHeight: 1,
        transition: 'color .2s linear',
        fontSize: '2rem',
  
        '&:hover': {
          color: [theme.palette.primary.main]
        },
      },
    },
  },
}))

const AboutSection = () => {

  const classes = useStyles()

  return (
    <Box component="section" className={classes.wrapper}>
      <Hidden smDown>
        <Box className={classes.bgImage}>
          <StaticImage width={924} layout="fixed" src="../../images/about-page/bg-image.png" alt="Веб студия Ascent" />
        </Box>
      </Hidden>
      <Container maxWidth="xl">
        <Box className={classes.content}>
          <Grid container >
            <Grid item lg={8}>
              <Box className={classes.titleBox} mb={4}>
                <Typography variant="h1" className={`${classes.title} title`}>О нас</Typography>
              </Box>
              <Box className={classes.aboutTextBox} mb={{xs: 4, md: 6}}>
                <Typography variant="body1" className={`${classes.aboutText} underlined`}>
                Веб-студия <span>ASCENT</span> - это  команда специалистов в маркетинге, программировании, дизайне и SEO-оптимизации. В своих работах мы руководствуемся, прежде всего, современным подходом к разработке web-продуктов, маркетингу и дизайну. Отслеживая все тенденции в этих направлениях, постоянно совершенствуем подход к разработке продукта. <br/>Мы всегда доводим сайт до результата и не бросаем своих клиентов на полпути. Мы за долгосрочные партнерские отношения. Многие наши клиенты продолжают сотрудничать с нами уже несколько лет. <br/>Заказывая сайт в нашей веб-студии, вы обеспечиваете своему бизнесу рост и развитие!
                </Typography>
                <Typography variant="body1" className={`${classes.aboutText}`}>
                Вы можете связаться с нами по телефону или e-mail, а также оставить заявку на сайте и мы сами, с удовольствием, вам перезвоним и проконсультируем по поводу разработки вашего продукта.
                </Typography>
              </Box>
              <Box className={classes.contactsContainer} >
                <Box className={classes.contactBox} mb={{xs: 2, md: 0}} mr={{xs: 0, md: 8}}>
                  <PhoneIcon />
                  <a href="tel:+79998430073">+7 (999) 843-00-73</a>
                </Box>
                <Box className={classes.contactBox}>
                  <MailIcon />
                  <a href="mailto:hello@ascent.ru">hello@ascentweb.ru</a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default AboutSection
