import React from 'react'
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import FormSection from '../../components/page-parts/form-section';
import AboutSection from '../../components/about-page/about-section';


const AboutPage = () => {

  const pageTitle = 'О нашей компании'
  // const pageDescription = 'Предоставляем различные услуги в сфере веб-разработки. Разработка сайтов (лендингов, корпоративных, квизов, интернет-магазинов), веб-дизайн, маркетинг и реклама, интеграция с сервисами автоматизации бизнеса.'
  // const keywords = ''

  return (
    <Layout fixedHeader={false}>
      <Seo title={pageTitle} />
      <AboutSection />
      <FormSection />
    </Layout>
  )
}

export default AboutPage
